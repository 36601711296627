import I18n from '@/global-components/i18n/i18n.vue'
import I18nFlags from '@/global-components/i18n/i18n-flags.vue'
import Pagination from '@/global-components/pagination/pagination.vue'
import Tippy from './tippy/Main.vue'
import TippyContent from './tippy-content/Main.vue'
import TomSelect from './tom-select/Main.vue'
import LoadingIcon from './loading-icon/Main.vue'
import EpicSpinners from './epic-spinners/Main.vue'
import TinySlider from './tiny-slider/Main.vue'
import Dropzone from './dropzone/Main.vue'
import * as featherIcons from '@zhuowenli/vue-feather-icons'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import ErrorNotify from '@/global-components/toastify-notify/error-notify.vue'
import SuccessNotify from '@/global-components/toastify-notify/success-notify.vue'
import SuccessNotifyWithButtons from '@/global-components/toastify-notify/success-notify-with-buttons.vue'
import AppButton from '@/global-components/button/AppButton.vue'
import TableFilter from '@/global-components/filters/table-filter.vue'
import ListItemImage from '@/global-components/list/list-item-image.vue'

export default app => {
  app.component('I18n', I18n)
  app.component('I18nFlags', I18nFlags)
  app.component('Pagination', Pagination)
  app.component('Tippy', Tippy)
  app.component('TippyContent', TippyContent)
  app.component('TomSelect', TomSelect)
  app.component('LoadingIcon', LoadingIcon)
  app.component('TinySlider', TinySlider)
  app.component('Dropzone', Dropzone)
  app.component('ErrorNotify', ErrorNotify)
  app.component('SuccessNotify', SuccessNotify)
  app.component('SuccessNotifyWithButtons', SuccessNotifyWithButtons)
  app.component('loading-spinner', EpicSpinners)
  app.component('AppButton', AppButton)
  app.component('TableFilter', TableFilter)
  app.component([ListItemImage.name], ListItemImage)
  app.use(VueTelInput)

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = '24'
    app.component(key, icon)
  }
}
