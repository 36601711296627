import { createStore } from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import main from './main'
import sideMenu from './side-menu'
import auth from '@/auth/auth-store'
import layout from '@/store/layout-store'
import menu from '@/store/menu/menu-store'
import request from '@/store/request/request-store'

const store = createStore({
  modules: {
    main,
    sideMenu,
    auth,
    layout,
    menu,
    request
  },
  mutations: {
    ...vuexfireMutations
  }
})

export function useStore() {
  return store
}

export default store
