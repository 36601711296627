/* eslint-disable */ 
import menuListStore from '@/store/menu/menu-list-store';
import menuFormStore from '@/store/menu/menu-form-store';
import menuViewStore from '@/store/menu/menu-view-store';
// import menuDestroyStore from '@/store/menu/menu-destroy-store';

export default {
  namespaced: true,

  modules: {
    list: menuListStore,
    form: menuFormStore,
    view: menuViewStore,
    // destroy: menuDestroyStore
  },
};
