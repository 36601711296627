const state = () => {
  return {
    menu: [
      {
        icon: 'MenuIcon',
        pageName: 'menu',
        title: 'Menu'
      }
      // 'devider'
    ]
  }
}

const getters = {
  menu: state => state.menu
}
const mutations = {}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
