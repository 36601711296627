/* eslint-disable */
import { MenuService } from '@/store/menu/menu-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';

const INITIAL_PAGE_SIZE = 10;
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  orderBy: 'createdAt',
  sortType: 'desc',
  action: 'next',
  firstPage: true,
  doc: {},
}

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: [],
    currentPage: 1,
    pagination: INITIAL_PAGINATION_INPUT,
    sorter: {},
    table: null,
    type: null,
  },

  getters: {
    loading: (state) => state.loading,
    exportLoading: (state) => state.exportLoading,
    rows: (state) => state.rows || [],
    count: (state) => state.count,
    hasRows: (state, getters) => getters.count > 0,
    filter: (state) => state.filter,
    currentPage: (state) => state.currentPage,
    type: (state) => state.type,
    table: (state) => state.table,
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
    },

    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },
    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    }
  },

  actions: {
    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

    async doFetch({ commit }) {
      try {
          commit('FETCH_STARTED')

          const response = await MenuService.myMenus()
  
          commit('FETCH_SUCCESS', {
            rows: response,
            count: response.length,
          })
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    }
  },
};
