// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA9992_q6GylvGQMIAxhUdQO4HJ0zJca1w',
  authDomain: 'nfc-menu-system.firebaseapp.com',
  projectId: 'nfc-menu-system',
  storageBucket: 'nfc-menu-system.appspot.com',
  messagingSenderId: '15003801614',
  appId: '1:15003801614:web:8fab93a7ea04dd539c445f',
  measurementId: 'G-G890VS3YGT'
}

const REGION = 'europe-west1'
const BASE_URL = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net`

// #region [ Cloud Functions ]
  const backendUrl = BASE_URL
  const backendUrlAuth = `${BASE_URL}/auth`
  const backendUrlMenu = `${BASE_URL}/menu`
  const backendUrlRequest = `${BASE_URL}/request`
// #endregion

// App Engine
// const backendUrl = `<insert app engine url here>`

export default {
  firebaseConfig,
  REGION,
  BASE_URL,
  backendUrl,
  backendUrlAuth,
  backendUrlMenu,
  backendUrlRequest
}
