/* eslint-disable */

import authGuardMixin from '@/auth/guards/auth-guard-mixin';
import unauthGuardMixin from '@/auth/guards/unauth-guard-mixin';
// import emailAlreadyVerifiedGuardMixin from '@/auth/guards/email-already-verified-guard-mixin';
import notEmptyPermissionsGuardMixin from '@/auth/guards/not-empty-permissions-guard-mixin';
// import permissionGuardMixin from '@/auth/guards/permission-guard-mixin';

const mixins = [
  authGuardMixin,
  unauthGuardMixin,
  // emailAlreadyVerifiedGuardMixin,
  notEmptyPermissionsGuardMixin,
//   permissionGuardMixin,
];

export { mixins }

export default app => {
  mixins.forEach(mixin => {
    app.mixin(mixin)
  })
}
