<template>
<div class="intro-y box p-5 mt-5 dark:bg-theme-dark-2">
  <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
    <form id="tabulator-html-filter-form" class="xl:flex" :class="isRTL ? 'sm:ml-auto' : 'sm:mr-auto'" v-if="filterFields.length">
      <div class="sm:flex items-center" :class="isRTL ? 'sm:ml-4' : 'sm:mr-4'">
        <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2">{{ i18n('common.table.filter.field') }}</label>
        <select
          id="tabulator-html-filter-field"
          v-model="filter.key"
          class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto dark:bg-dark-9"
        >
          <option v-for="(field, index) in filterFields" :key="index" :value="field.value">{{ field.label }}</option>
        </select>
      </div>
      <div class="sm:flex items-center mt-2 xl:mt-0" :class="isRTL ? 'sm:ml-4' : 'sm:mr-4'">
        <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2">{{ i18n('common.table.filter.operator') }}</label>
        <select
          id="tabulator-html-filter-field"
          v-model="filter.operator"
          class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto dark:bg-dark-9 text-center"
        >
          <option v-for="(operator, index) in operations" :key="index" :value="operator.value">{{ operator.label }}</option>
        </select>
      </div>
      <div class="sm:flex items-center mt-2 xl:mt-0" :class="isRTL ? 'sm:ml-4' : 'sm:mr-4'">
        <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2">{{ i18n('common.table.filter.value') }}</label>
        <input
          id="tabulator-html-filter-value"
          v-model="filter.value"
          type="text"
          class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0 dark:bg-dark-9"
        />
      </div>
      <div class="mt-2 xl:mt-0">
        <button id="tabulator-html-filter-go"
          type="button"
          class="btn bg-theme-31 text-white w-full sm:w-16 sm:mr-3"
          :disabled="disabled"
          @click="$emit('filter', filter)"
        >
          {{ i18n('common.table.filter.go') }}
        </button>
        <button id="tabulator-html-filter-reset" type="button"
          class="btn bg-theme-32 text-black w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1 sm:mr-1"
          :disabled="disabled"
          @click="$emit('reset')"
        >
          {{ i18n('common.table.filter.reset') }}
        </button>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'
// const inputTypes = ['text', 'number', 'date', 'date-range', 'email', '']

export default defineComponent({
  props: {
    filterFields: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filter: {
        key: this.filterFields.length ? this.filterFields[0].value : '',
        operator: 'equal',
        value: ''
      },
      operations: [
        { label: '<', value: 'less' },
        { label: '<=', value: 'lessEqual' },
        { label: '==', value: 'equal' },
        { label: '>', value: 'greater' },
        { label: '>=', value: 'greaterEqual' },
        { label: '!=', value: 'notEqual' },
        { label: 'Start with', value: 'startsWith' }
      ]
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style lang="scss">

</style>
