/* eslint-disable */     
import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import * as uuid from 'uuid/v4'
import filesize from 'filesize'
// const webp = require('webp-converter');

// this will grant 755 permission to webp executables
// webp.grant_permission();

function extractExtensionFrom(filename) {
  if (!filename) {
    return null
  }

  const regex = /(?:\.([^.]+))?$/
  return regex.exec(filename)[1]
}

export class FileUploader {
  static validate(file, schema) {
    if (!schema) {
      return
    }
    if (schema.image) {
      if (!file.type.startsWith('image')) {
        throw new Error('fileUploader.image')
      }
    }

    if (schema.size && file.size > schema.size) {
      throw new Error('fileUploader.size', filesize(schema.size))
    }

    const extension = extractExtensionFrom(file.name)

    if (schema.formats && !schema.formats.includes(extension)) {
      throw new Error('fileUploader.formats', schema.formats.join('/'))
    }
  }

  static uploadFromRequest(path, request, schema) {
    try {
      FileUploader.validate(request.file, schema)
    } catch (error) {
      request.onError(error)
      console.log(error)
      return
    }

    const ref = firebase.storage().ref()
    console.log(request)
    const extension = extractExtensionFrom(request.name)
    const id = uuid()
    const fullPath = `${path}/${id}.${extension}`
    const task = ref.child(fullPath).put(request)
    debugger
    console.log(request)
    // webp.grant_permission();
    // const result = webp.cwebp(request.file,`${path}/${id}.webp`,"-q 80");
    // result.then((response) => {
    //   console.log(response);
    // });

    task.on(
      //   console.log('1'),
      firebase.storage.TaskEvent.STATE_CHANGED,
      () => { },
      //   console.log('2'),
      //   snapshot => {
      //     const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

      //     setTimeout(() => {
      //       request.onProgress({ percent })
      //     }, 0)
      //   },
      error => {
        console.log(error)
        // request.onError(error)
      },
      async () => {
        await task.snapshot.ref
          .getDownloadURL()
          .then(url => {
            debugger
            return url
            // request.onSuccess({
            //   id: id,
            //   name: request.name,
            //   sizeInBytes: task.snapshot.totalBytes,
            //   privateUrl: fullPath,
            //   publicUrl: url,
            //   new: true
            // })
            // console.log(url)
            // debugger
          })
          .catch(error => {
            console.log(error)
            // request.onError(error)
          })
      }
    )
  }
  //   eslint-disable-next-line
  static async upload(path, request) {
    let uploads
    const promises = []
    const ref = firebase.storage().ref()
    const file = request
    // const currentDate = new Date()
    // const timestamp = currentDate.getTime()
    // const fullPath = `${path}/${timestamp}.${extractExtensionFrom(file.name)}`
    const fullPath = `${path}.${extractExtensionFrom(file.name)}`
    const task = ref.child(fullPath)
    const uploadPromise = task.put(file).then(async () => {
      const downloadURL = await task.getDownloadURL()
      uploads = {
        name: file.name,
        publicUrl: downloadURL
      }
    })
    promises.push(uploadPromise)
    await Promise.all(promises)
    console.log(uploads)
    return uploads
  }
}
