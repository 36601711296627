import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import i18n from './vueI18n'
import './libs'
// import guardMixin from '@/auth/guards'
import 'epic-spinners/dist/lib/epic-spinners.min.css'
import 'material-icons/iconfont/material-icons.css'
// SASS Theme
import './assets/sass/app.scss'
// import 'flowbite'
// import guardMixin from '@/auth/guards'
import { mixins } from '@/auth/guards'

const app = createApp(App, { mixins: mixins })
  .use(store)
  .use(router)
  .use(i18n)

console.log(mixins)
// mixins.forEach((mixin) => {
//   app.mixin(mixin)
// })
// app.mixin({ ...guardMixin })
// guardMixin(app)
globalComponents(app)
utils(app)

app.mount('#app')
