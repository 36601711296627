/* eslint-disable */     
import { MenuService } from '@/store/menu/menu-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';
// import { routerAsync } from '@/app-module';
import router from '@/router'
import { FileUploader } from '@/shared/firebase/file-uploader'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },
    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },
    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },
    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },
    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await MenuService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        router.push('/');
      }
    },

    async doCreate({ commit, dispatch, rootGetters }, { values, file }) {
      try {
        const currentUser = rootGetters[`auth/currentUser`]
        const menuNumber = currentUser.numberOfMenusUsed + 1
        const path = `meuns/${currentUser.id}/Menu-${menuNumber}`

        commit('CREATE_STARTED');
        const { publicUrl } = await FileUploader.upload(path, file)
        values.file = publicUrl
        values.menuNumber = menuNumber
        await MenuService.create(values);
        commit('CREATE_SUCCESS');

        dispatch('auth/doRefreshCurrentUser', {}, { root: true });
        Message.success(i18n('menuPage.create.success'));
        router.push('/');
      } catch (error) {
        Message.error(error.message);
        Errors.handle(error);
        commit('CREATE_ERROR');
        router.push('/');
      }
    },

    async doUpdate({ commit, dispatch, rootGetters }, { id, values, file }) {
      try {
        const currentUser = rootGetters[`auth/currentUser`]
        const path = `meuns/${currentUser.id}/Menu-${values.menuNumber}`

        commit('UPDATE_STARTED');
        const { publicUrl } = await FileUploader.upload(path, file)
        values.file = publicUrl
        await MenuService.update(id, values);
        commit('UPDATE_SUCCESS');

        dispatch('auth/doRefreshCurrentUser', {}, { root: true });
        Message.success(i18n('menuPage.update.success'));
        router.push('/');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
        router.push('/');
      }
    },
  },
};
