/* eslint-disable */

// import graphqlClient from '@/shared/graphql/client'
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlRequest)

export class RequestService {
//#region [ Mutation APIs ]
  /**
   * Create new request
   * @param {Object} data
   * @param {Object} data.name
   * @param {String} data.name.en
   * @param {String} data.name.ar
   * @param {String} data.file - The request url
   * @returns
   */
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REQUEST_CREATE($data: RequestInput!) {
          requestCreate(data: $data) {
            id
            menusNeeded
            status
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        data
      }
    })

    return response.data.requestCreate
    // if (response.data.requestCreate && response.data.requestCreate.status) {
    //   return response.data.requestCreate.result
    // } else {
    //   console.error(response.data.requestCreate.error);
    //   throw response.data.requestCreate.error
    // }
  }
//#endregion

//#region [ Query APIs ]
  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query REQUEST_FIND($id: String!) {
          requestFind(id: $id) {
            id
            menusNeeded
            status
            requester {
                id
                fullName
                email
            }

            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.requestFind
  }

  static async myRequests() {
    const response = await graphqlClient.query({
      query: gql`
        query MY_REQUESTS {
          myRequests {
            id
            menusNeeded
            status
            requester {
                id
                fullName
                email
            }

            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `
    })

    return response.data.myRequests
  }
//#endregion
}
