/* eslint-disable */ 
import requestListStore from '@/store/request/request-list-store';
import requestFormStore from '@/store/request/request-form-store';
// import requestViewStore from '@/store/request/request-view-store';
// import requestDestroyStore from '@/store/request/request-destroy-store';

export default {
  namespaced: true,

  modules: {
    list: requestListStore,
    form: requestFormStore,
    // view: requestViewStore,
    // destroy: requestDestroyStore
  },
};
