/* eslint-disable */

// import graphqlClient from '@/shared/graphql/client'
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlMenu)

export class MenuService {
//#region [ Mutation APIs ]
  /**
   * Create new menu
   * @param {Object} data
   * @param {Object} data.name
   * @param {String} data.name.en
   * @param {String} data.name.ar
   * @param {String} data.file - The menu url
   * @param {Int} data.menuNumber
   * @returns
   */
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation MENU_CREATE($data: MenuInput!) {
          menuCreate(data: $data) {
            id
            name {
              en
              ar
            }
            file
            menuNumber
            createdAt
          }
        }
      `,

      variables: {
        data
      }
    })

    return response.data.menuCreate
    // if (response.data.menuCreate && response.data.menuCreate.status) {
    //   return response.data.menuCreate.result
    // } else {
    //   console.error(response.data.menuCreate.error);
    //   throw response.data.menuCreate.error
    // }
  }

  /**
   * Update menu
   * @param {String} id
   * @param {Object} data
   * @param {Object} data.name
   * @param {String} data.name.en
   * @param {String} data.name.ar
   * @param {String} data.file - The menu url
   * @param {Int} data.menuNumber
   * @returns
   */
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation MENU_UPSATE($id: String!, $data: MenuInput!) {
          menuUpdate(id: $id, data: $data) {
            id
            name {
              en
              ar
            }
            file
            menuNumber
            createdAt
          }
        }
      `,

      variables: {
        id,
        data
      }
    })

    return response.data.menuUpdate
    // if (response.data.menuUpdate && response.data.menuUpdate.status) {
    //   return response.data.menuUpdate.result
    // } else {
    //   console.error(response.data.menuUpdate.error);
    //   throw response.data.menuUpdate.error
    // }
  }
//#endregion

//#region [ Query APIs ]
  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query MENU_FIND($id: String!) {
          menuFind(id: $id) {
            id
            name {
              en
              ar
            }
            file
            accountId
            menuNumber

            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.menuFind
  }

  static async myMenus() {
    const response = await graphqlClient.query({
      query: gql`
        query MY_MENUS {
          myMenus {
            id
            name {
              en
              ar
            }
            file
            accountId
            menuNumber

            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `
    })

    return response.data.myMenus
  }
//#endregion
}
